import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Label } from "expano-components";
import { Button, MainTable, Main, Dashboard } from "components";
import { parseQuery } from "utils/api";
import {
  getProductGroups,
  filterProductGroups,
  toggleFilters,
} from "actions/product_groups";
import getPlatformOptions from "utils/select_options/platform_options";
import getShopOptions from "utils/select_options/shop_options";
import getCategoryOptions from "utils/select_options/category_options";
import getProducerOptions from "utils/select_options/producer_options";
import getBrandOptions from "utils/select_options/brand_options";

import { ReactComponent as ProductGroupIcon } from "icons/product_group.svg";

const ProductGroupsList = ({
  product_groups,
  toggleFilters,
  getProductGroups,
  filterProductGroups,
  history,
  location: { search },
}) => {
  const query = parseQuery(search);
  const [is_loaded, setLoaded] = useState(false);
  const sort = {
    "sort[column]": query["sort[column]"] || "id",
    "sort[order]": query["sort[order]"] || "asc",
  };

  useEffect(() => {
    getProductGroups({ ...query, ...sort });
    setLoaded(true);
  }, [search]);

  useEffect(() => {
    if (is_loaded) {
      getProductGroups({ ...query, page: undefined });
    }
  }, [product_groups.filters.query]);

  const { data, meta } = product_groups;

  return (
    <Main
      page={{
        breadcrumbs: [{ name: "Produkty grupowe" }],
        name: "Produkty grupowe",
        icon: <ProductGroupIcon />,
        buttons: (
          <Button
            type="add"
            text="Dodaj produkt grupowy"
            path="/product_groups/new"
          />
        ),
      }}
    >
      <Dashboard>
        <MainTable
          is_loading={["invalid", "loading"].includes(product_groups.status)}
          is_searching={product_groups.status === "searching"}
          filters_open={product_groups.filters.open}
          toggleFilters={toggleFilters}
          data={data}
          meta={meta}
          sort={sort}
          head={[
            { label: "Nazwa", name: "name", style: { width: 250 } },
            { label: "SKU", name: "sku", style: { width: 150 } },
            {
              label: "Kanał",
              name: "channel",
              sortable: false,
            },
            { label: "Kategoria", name: "category", style: { width: 200 } },
            { label: "Producent", name: "producer", style: { width: 200 } },
          ]}
          empty_text="Brak produktów grupowych"
          renderRow={({
            id,
            name,
            sku,
            channels,
            category,
            producer,
          }) => (
            <tr
              key={id}
              style={{ cursor: "pointer" }}
              onMouseDown={(event) => {
                if (event.button === 1) {
                  window.open(`/product_groups/${id}`, "_blank");
                }
              }}
              onClick={() => history.push(`/product_groups/${id}`)}
            >
              <td>{name}</td>
              <td>{sku}</td>
              <td>
                {channels?.length > 0
                  ? channels.map(({ shop, platform }) => (
                      <Label
                        type="double"
                        left={{
                          text: shop?.label_text,
                          color: `#${shop?.label_color}`,
                        }}
                        right={{
                          text: platform?.label_text,
                          color: `#${platform?.label_color}`,
                        }}
                      />
                    ))
                  : "brak"}
              </td>
              <td>{category ? category.name : "brak"}</td>
              <td>{producer ? producer.name : "brak"}</td>
            </tr>
          )}
          filtersAction={filterProductGroups}
          filters={[
            {
              type: "input",
              label: "Sku lub Nazwa produktu",
              name: "sku_or_name",
              search_type: "matches",
            },
            {
              type: "async-multi-select",
              label: "Producent",
              search_type: "in",
              name: "producer_id",
              asyncAction: getProducerOptions,
            },
            {
              type: "async-multi-select",
              label: "Marka",
              search_type: "in",
              name: "brand_id",
              asyncAction: (q) => getBrandOptions(q, null),
            },
            {
              type: "async-multi-select",
              label: "Kategoria",
              search_type: "in",
              name: "category_id",
              asyncAction: getCategoryOptions,
            },
            {
              type: "async-multi-select",
              label: "Sklep",
              name: "shop_id",
              search_type: "in",
              asyncAction: getShopOptions,
            },
            {
              type: "async-multi-select",
              label: "Platforma",
              search_type: "in",
              name: "platform_id",
              asyncAction: getPlatformOptions,
            },
          ]}
        />
      </Dashboard>
    </Main>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getProductGroups: (data) => dispatch(getProductGroups(data)),
  filterProductGroups: (data) => dispatch(filterProductGroups(data)),
  toggleFilters: () => dispatch(toggleFilters()),
});
export default connect(
  ({ product_groups }) => ({ product_groups }),
  mapDispatchToProps
)(ProductGroupsList);
